<template>
  <div class="with-nav-bar">
    <van-nav-bar
      title="充值记录"
      left-text="返回"
      :right-text="!state.loading ? '充值' : ''"
      :left-arrow="true"
      :fixed="true"
      @click-left="$router.back()"
      @click-right="!state.loading && $router.push({ name: 'UserRecharge' })"
    />
    <van-dropdown-menu class="van-hairline--bottom" active-color="#1989fa">
      <van-dropdown-item
        v-model="state.status"
        :options="allStatus"
        @change="onStatusChange"
      />
      <van-dropdown-item title="日期" @open="state.showCalendar = true" />
    </van-dropdown-menu>
    <van-calendar
      v-model:show="state.showCalendar"
      type="range"
      position="right"
      color="#1989fa"
      :allow-same-day="true"
      :min-date="new Date(new Date() - 2 * 15552000000)"
      :max-date="new Date()"
      :round="false"
      @confirm="onDateRangeConfirm"
    />
    <van-list
      v-model:loading="state.loading"
      v-model:error="state.error"
      :finished="state.finished"
      finished-text="没有更多了"
      error-text="拉取失败，点击重新加载"
      @load="loadPage"
    >
      <van-cell
        v-for="(item, index) in state.items"
        :key="index"
        :to="{ name: 'UserRechargeDetail', query: { trade_no: item.trade_no } }"
        is-link
      >
        <template #title>
          {{ item.payment_time }}
          <van-tag v-if="item.status != 1" type="warning" plain>退</van-tag>
        </template>
        <template #value>
          <span :class="item.status == 1 ? 'text-success' : 'text-secondary'"
            >+{{ new Number(item.amount / 100).toFixed(2) }}元</span
          >
        </template>
      </van-cell>
    </van-list>
  </div>
</template>

<script>
import { reactive } from "vue";
import { Tag, List, Toast, Calendar, DropdownMenu, DropdownItem } from "vant";
import { getRechargeLog } from "@/api/account.service";
export default {
  name: "RechargeLog",
  components: {
    [Tag.name]: Tag,
    [List.name]: List,
    [Calendar.name]: Calendar,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
  },

  setup() {
    const state = reactive({
      page: 1,
      items: [],
      loading: false,
      finished: false,
      showCalendar: false,

      // 搜索表单字段
      status: -1,
      start_date: "",
      end_date: "",
    });

    const allStatus = [
      { text: "全部", value: -1 }, // 注意: 全部只包含以下全部状态, 不包含支付失败和已关闭订单
      { text: "支付成功", value: 1 },
      { text: "退款中", value: 4 },
      { text: "部分退款", value: 5 },
      { text: "已退款", value: 6 },
    ];

    const loadPage = async () => {
      state.loading = true;
      if (state.page == 1) {
        state.items = [];
      }
      try {
        const { result } = await getRechargeLog({
          page: state.page,
          status: state.status,
          start_date: state.start_date,
          end_date: state.end_date,
        });
        if (result.items.length > 0) {
          state.items.push(...result.items);
          state.page++;
        }
        if (!result.hasNext || result.items.length == 0) {
          state.finished = true;
        }
        state.loading = false;
      } catch (error) {
        state.loading = false;
        state.error = true;
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        Toast({ message: msg ? msg : "前端脚本异常", position: "bottom" });
        if (!msg) console.log(error);
      }
    };

    const onStatusChange = () => {
      state.page = 1;
      loadPage();
    };

    const formatDate = (date) =>
      `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

    const onDateRangeConfirm = (values) => {
      const [start, end] = values;
      state.start_date = formatDate(start);
      state.end_date = formatDate(end);
      state.showCalendar = false; // 提示: 这样会导致日历立即隐藏, 没有动画
      state.page = 1;
      loadPage();
    };

    return { state, allStatus, loadPage, onStatusChange, onDateRangeConfirm };
  },
};
</script>
